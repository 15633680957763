<script setup lang="ts"></script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
    >
        <path
            d="M61 32C61 39.6913 57.9447 47.0675 52.5061 52.5061C47.0675 57.9447 39.6913 61 32 61C24.3087 61 16.9325 57.9447 11.4939 52.5061C6.05535 47.0675 3 39.6913 3 32"
            stroke="url(#paint0_angular_471_4497)"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M61 32C61 39.6913 57.9447 47.0675 52.5061 52.5061C47.0675 57.9447 39.6913 61 32 61C24.3087 61 16.9325 57.9447 11.4939 52.5061C6.05535 47.0675 3 39.6913 3 32"
            stroke="url(#paint1_angular_471_4497)"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M3 32C3 24.3087 6.05535 16.9325 11.4939 11.4939C16.9325 6.05535 24.3087 3 32 3C39.6913 3 47.0675 6.05535 52.5061 11.4939C57.9447 16.9325 61 24.3087 61 32"
            stroke="url(#paint2_angular_471_4497)"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <defs>
            <radialGradient
                id="paint0_angular_471_4497"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(32 32) scale(32)"
            >
                <stop stop-color="#50A0EB" />
                <stop
                    offset="0.510997"
                    stop-color="#50A0EB"
                    stop-opacity=".4"
                />
            </radialGradient>
            <radialGradient
                id="paint1_angular_471_4497"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(32 32) scale(32)"
            >
                <stop stop-color="#50A0EB" />
                <stop offset="0.510997" stop-color="#50A0EB" stop-opacity="1" />
            </radialGradient>
            <radialGradient
                id="paint2_angular_471_4497"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(32 32) rotate(-180) scale(32)"
            >
                <stop stop-color="#50A0EB" />
                <stop
                    offset="0.512152"
                    stop-color="#50A0EB"
                    stop-opacity=".4"
                />
            </radialGradient>
        </defs>
    </svg>
</template>
